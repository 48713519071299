@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@300;400;500&family=Roboto:wght@100;300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.h1 {
    font-family: 'Roboto', sans-serif;
}

.circle-singleline {
      margin: 0 auto;
      width: 100px;
      height: 100px;
      border-radius: 70%;
      font-size: 60px;
      line-height: 90px;
      text-align: center;    
      background: #808080;
      color: #fff;
      justify-content: center;
      align-items: center;
      display: flex;


  }


.card {
    margin: 40px auto
}

.card_:hover {
    transform: scale(1.08)
}


.btn_ {
    width: 200px;
    height: 60px;
    font-size: 20px;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: #D95830;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;


}


.btn_nav {
  width: 250px;
  height: 60px;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #D95830;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  


}

.cards_hover:hover{

    box-shadow: 5px 6px 6px 2px #e9ecef;
    transform: scale(1.1);
}

.btn_chk {

  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 70px; 
  background-color: black;
  color: #333;
  text-align: center;


  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}


._btn {

  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 70px; 
  background-color: #eee;
  color: #333;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

._btn-danger {

  background-color: #f39447;
  border-color :#f69444;;
  color: #fff;
}


.carousel-item .carousel-caption {
  position: absolute;
  right: 15%;
  top: 2px;
  left: 0%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align:right;
}


.btn-primary {
  border-color: black;
}
.total {
    display: flex;
    justify-content: space-between;
  }

  .bs-example{
    margin: 20px;
}
